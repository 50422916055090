import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "answer", "submit" ]

  connect() {
    console.log("in Questions")
  }
    
    answer = (e) => {
        let btn_val = e.target.childNodes[0].nodeValue.trim()
        let field = this.answerTarget
        let submit_btn = this.submitTarget
        console.log(btn_val)
        field.value = btn_val
        submit_btn.click()
}
}
