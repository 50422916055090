import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [];

  connect() {
    let score_element = document.getElementById("myChart");
    let score = parseFloat(score_element.dataset.score);
    var data = {
      labels: ["Achieved", ""],
      datasets: [
        {
          data: [score, 100 - score],
          backgroundColor: ["#9B51E0", "#828282"],
          hoverBackgroundColor: ["#9B51E0", "#828282"],
        },
      ],
    };

    var promisedDeliveryChart = new Chart(document.getElementById("myChart"), {
      type: "doughnut",
      data: data,
      options: {
        cutoutPercentage: 60,
        responsive: true,
        legend: {
          display: false,
        },
      },
    });

    Chart.pluginService.register({
      beforeDraw: function (chart) {
        var width = chart.chart.width,
          height = chart.chart.height,
          ctx = chart.chart.ctx;

        ctx.restore();
        var fontSize = (height / 80).toFixed(2);
        ctx.font = fontSize + "em sans-serif";
        ctx.textBaseline = "middle";
        ctx.fillStyle = "#9B51E0";

        var text = `${score}%`,
          textX = Math.round((width - ctx.measureText(text).width) / 2),
          textY = height / 2;

        ctx.fillText(text, textX, textY);
        ctx.save();
      },
    });
    console.log("CHART CONNECT");
  }
}
