import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["button"];

  connect() {
    console.log("Hello, TOC!");
    console.log(this.buttonTarget);
    this.buttonTarget.classList.add("hidden");
  }

  checkchange = (e) => {
    console.log(e.target);
    if (e.target.checked) {
      this.buttonTarget.classList.remove("hidden");
    } else {
      this.buttonTarget.classList.add("hidden");
    }
  };
}
